// Customizable Area Start
import { IBlock } from '../../../../../framework/src/IBlock';
import { Message } from '../../../../../framework/src/Message';
import { BlockComponent } from '../../../../../framework/src/BlockComponent';
import MessageEnum, {
	getName
} from '../../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../../framework/src/RunEngine';
import actionCable from 'actioncable';
import moment from 'moment';
import {
	RemoteVideoTrack, AudioTrack, LocalParticipant,
	Participant
} from 'twilio-video';
export const configJSON = require('../config.js');
import { RouteComponentProps } from "react-router-dom";
import { getStorageData, setStorageData } from '../../../../../framework/src/Utilities';
const config = require('../../../../../framework/src/config.js')

export interface Props extends RouteComponentProps {
	room: any;
	handleStateUplifting: (x: any) => void
	startTime: string,
	endTime: string,
	allRoomParticipants: any,
	roomTopic: string,
	myAccountId: number,
	myWalletBalance: number,
	roomPrice: number,
	isModerated: boolean,
	room_type: string,

	feedbackData: any;
	sendRoomExtensionRequest: () => void;
	getRoomDetails: any;
	joined_users: string[];
	on_call_users: string[];
	hostId: number;
	isMobile: boolean;

	roomDescription: string;
	isRoomDescriptionOpen: boolean;
	roomImgLink: string;
	toggleRoomDescription: () => void;
	createFeedbackUsersArray: () => void;
	sendConnectionRequest: (id: number) => void;
	revertConnectionRequest: (id: number) => void;
	handleDisconnectUser: (id: number) => void;

	currentUnixTime: number | null;
}

interface S {
	alertNotification: boolean,
	alertNotificationMessage: string,
	leaveRequestNotification: boolean,
	leaveRequestNotificationMessage: string,
	leaveRequestNotificationCount: number,
	showLeaveButton: boolean,
	showGreenModal: boolean,

	selfAlertCount: number,
	selfLeaveCount: number,
	showMaxAlert: boolean,
	showMaxLeave: boolean,
	selfLeave: boolean,

	audioStatus: boolean;
	videoStatus: boolean;
	d: any;
	local: any;
	allActiveParticipants: any;
	identityAndId: any;

	leave: boolean;
	icebreakerText: string;
	mainVideoTrackDisabled: boolean;
	currentUser: any;
	leaveModalOpen: boolean;
	retractText: string;
	showCancelModal: boolean;
}

interface SS {
	id: any;
}

export default class VideoScreenController extends BlockComponent<Props, S, SS> {
	iceBreakerMessageId: string;
	forceRemoveUserId: string;
	leaveRoomForUserID: string;
	verifyCurrentTime: string;
	leaveCallForBothUserID: string;
	earnedYCoinsID: string;

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		this.subScribedMessages = [
			getName(MessageEnum.RestAPIResponceMessage)
		];

		this.state = {
			alertNotification: false,
			alertNotificationMessage: "",
			leaveRequestNotification: false,
			leaveRequestNotificationMessage: "",
			showLeaveButton: false,
			leaveRequestNotificationCount: 0,
			showGreenModal: false,

			selfAlertCount: 0,
			selfLeaveCount: 0,
			showMaxAlert: false,
			showMaxLeave: false,
			selfLeave: false,

			audioStatus: true,
			videoStatus: true,
			allActiveParticipants: [],
			identityAndId: null,
			d: null,
			local: null,

			leave: false,
			icebreakerText: "",
			mainVideoTrackDisabled: false,
			currentUser: null,
			leaveModalOpen: false,
			retractText: '',
			showCancelModal: false,
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}
	// set current user data
	handleCurrentUser = async (participant: any) => {
		const UID = Number(await getStorageData("userId") as string);

		if (UID === participant.account_id) {
			this.setState({
				currentUser: {
					"account_id": participant.account_id,
					"is_host": participant?.is_host || false,
					"is_moderator": participant?.is_moderator || false,
					"is_anonymously": participant.is_anonymously,
					"anonymously_name": participant.anonymously_name,
					"profile_img": participant.profile_img,
					"full_name": participant?.full_name || '',
					"alert_request_sent_count": participant?.alert_request_sent_count || 0,
				}
			})
		}
	}

	// converting the allRoomParticipants to map
	handleRoomParticapnts = () => {

		let identityAndId = new Map();
		this.props.allRoomParticipants?.forEach((participant: any) => {

			if (!this.state.currentUser) this.handleCurrentUser(participant);

			identityAndId.set(participant.user_name, {
				"account_id": participant.account_id,
				"is_anonymously": participant.is_anonymously,
				"anonymously_name": participant.anonymously_name,
				"profile_img": participant.profile_img,
				"is_host": participant?.is_host || false,
				"is_moderator": participant?.is_moderator || false,
				"full_name": participant?.full_name || '',
				"alert_request_sent_count": participant?.alert_request_sent_count || 0,
				"retract_leave_status": participant?.retract_leave_status,
			})
		});

		this.setState({ identityAndId });
	}

	//if user again re-enters in the old room then update older states from local
	handleReEnter = async () => {
		//if user again re-enters in the old room then update older states from local
		if (await getStorageData('oldRoomId') === this.props.history.location.pathname.split('/')[2]) {
			let leaveRequestNotificationCount = parseInt(await getStorageData('leaveRequestNotificationCount') || "0");
			this.setState({ showLeaveButton: await getStorageData('showLeaveButton') === "true", leaveRequestNotificationCount })
		} else {
			//if oldroom does not matches with current room so removing it will make it error free
			localStorage.removeItem('leaveRequestNotificationCount');
			localStorage.removeItem('showLeaveButton');
			localStorage.removeItem('callRequestId');
			localStorage.removeItem('callRequestAccountId');
			localStorage.removeItem('iceBreakerCalled');
		}
	}

	// handle room updates 
	handleRoomUpdates = (room_update: string | undefined) => {
		if (!room_update) return;

		if (room_update === "Room is cancelled by host") {
			this.setState({ showCancelModal: true });
		}
	}

	// handle ice breaker
	handleIceBreaker = (ice_break_text: string | undefined) => {
		if (!ice_break_text) return;

		if (!this.props.isModerated && ice_break_text) {
			this.setState({ icebreakerText: ice_break_text }, () => {
				setTimeout(() =>
					this.setState({ icebreakerText: "" }), 60 * 1000); // after 1 min, the ice-breaker will disappeared
			})
		}
	}

	// handle alert
	handleAlert = (name: string | undefined, reason: string | undefined) => {
		if (!name || !reason) return;

		if (name === "alert") {
			this.setState({
				alertNotification: true,
				alertNotificationMessage: reason
			}, () => {
				setTimeout(() =>
					this.setState({ alertNotification: false }), 5000)
			});
		}
	}

	// handle retract leave
	handleRetractLeave = () => {
		if (this.state.leaveRequestNotificationCount > 1) {
			setStorageData('leaveRequestNotificationCount', (this.state.leaveRequestNotificationCount - 1).toString());
			setStorageData('oldRoomId', this.props.history.location.pathname.split('/')[2]);

			this.setState({ leaveRequestNotificationCount: this.state.leaveRequestNotificationCount - 1, showLeaveButton: true })
		} else {
			setStorageData('leaveRequestNotificationCount', "0");
			setStorageData('oldRoomId', this.props.history.location.pathname.split('/')[2]);
			this.setState({
				leaveRequestNotification: false,
				leaveRequestNotificationCount: 0,
				showGreenModal: true,
				retractText: "A user has retracted the \"ask to leave\" request against you.",
			}, () => {
				setTimeout(() => {
					this.setState({ showGreenModal: false, retractText: '' })
					setStorageData('showLeaveButton', "false")
					setStorageData('oldRoomId', this.props.history.location.pathname.split('/')[2]);
				}, 3000)

				setTimeout(() => this.setState({ showLeaveButton: false }), 4000)
			})
		}
	}


	// initilize action cabel
	initilizeSocket = async () => {
		const token = await getStorageData('token');
		const room_id = this.props.history.location.pathname.split('/')[2];

		const cable = actionCable.createConsumer(
			`${config.baseURL}/cable?token=${token}`
		);

		// RoomsChannel
		cable.subscriptions.create(
			{
				channel: "RoomsChannel",
				room_id,
				account_id: this.props.myAccountId
			},
			{
				connected: () => {
					this.sendIceBreakerRequest();
					setStorageData('oldRoomId', room_id);
				},
				disconnected: () => { },
				received: (data) => {
					this.handleRoomUpdates(data?.room_update);
					this.handleIceBreaker(data?.ice_break_text);
					this.handleAlert(data?.name, data?.reason);

					if (data.retract) {
						this.handleRetractLeave();
					}
					else if (data.count === 1) {
						setStorageData('leaveRequestNotificationCount', "1");
						setStorageData('oldRoomId', room_id);
						this.setState({
							leaveRequestNotification: true,
							leaveRequestNotificationCount: 1,
							leaveRequestNotificationMessage: data.reason
						}, () =>
							setTimeout(() => {
								this.setState({ leaveRequestNotification: false, showLeaveButton: true, leaveRequestNotificationMessage: '' })
								setStorageData('showLeaveButton', "true")
								setStorageData('oldRoomId', room_id);

								if (this.props.isModerated) {
									setStorageData('leaveRequestNotificationCount', data.count.toString());
									setStorageData('oldRoomId', room_id);
									this.setState({ showLeaveButton: false });
								}
							}, 5000)
						);
					}
					else if (data.count > 1) {
						setStorageData('leaveRequestNotificationCount', data.count.toString());
						setStorageData('oldRoomId', room_id);
						this.setState({ leaveRequestNotificationCount: this.state.leaveRequestNotificationCount + 1, showLeaveButton: false });
					}
				}
			}
		);
	}

	handleRemoteParticipant = () => {
		const setMainVideoTrack = () => {
			this.setState({ mainVideoTrackDisabled: false });
			this.attachingDominantSpeaker();
		};

		this.state.allActiveParticipants.map((rp: any) => {
			rp.on('trackSubscribed', (track: RemoteVideoTrack) => {
				if (track?.kind === "video") {
					setMainVideoTrack();
				}

				track.on('enabled', (e: any) => {
					if (e.kind === "video") setMainVideoTrack();
				});
			});

			rp.on('trackUnsubscribed', () => {
				this.setState({ mainVideoTrackDisabled: true });
			});

		});
	}

	retrieveStatusFromURL(url: string) {
		const urlParams = new URLSearchParams(url.slice(url.indexOf('?') + 1));
		const audioStatus = urlParams.get('audio') === 'true';
		const videoStatus = urlParams.get('video') === 'true';

		return { audio: audioStatus, video: videoStatus };
	}

	async componentDidMount() {
		super.componentDidMount();

		const status = this.retrieveStatusFromURL(this.props.location.pathname + this.props.location.search);

		this.handleRoomParticapnts();
		this.handleReEnter();
		this.initilizeSocket();

		// getting remote and local participant and adding eventListener at initial render
		const remote: Participant[] = Array.from(this.props.room.participants.values());
		const remoteAndLocal: (LocalParticipant | Participant)[] = [this.props.room.localParticipant, ...remote]
		this.setState({
			allActiveParticipants: remoteAndLocal,
			local: this.props.room.localParticipant
		});

		// Disable video on initial load if user wants
		if (!status.video) {
			setTimeout(() => {
				let videoTrackPublication: any = Array.from(this.props.room.localParticipant?.videoTracks?.values());
				if (videoTrackPublication) {
					this.setState({ videoStatus: false }, () => {
						this.disableVideo(videoTrackPublication);
					});
				}
			}, 50);
		}

		// Disable audio on initial load if user wants
		if (!status.audio) {
			setTimeout(() => {
				this.handleAudio()
			}, 100);
		}


		this.props.room.on('dominantSpeakerChanged', (participant: any) => {
			this.setState({
				d: participant
			})
		});

		this.props.room.on('participantConnected', (participant: any) => {
			this.props.getRoomDetails();

			this.addParticipant(participant);
		});

		this.props.room.on('participantDisconnected', (participant: any) => {
			this.removeParticipant(participant);
		});

		window.addEventListener('pagehide', () => this.props.room.disconnect());
		window.addEventListener('beforeunload', () => this.props.room.disconnect());

		// scroll to top
		window.scrollBy({
			top: -window.scrollY,
			left: 0,
		});

		// clear self_leave status
		sessionStorage.setItem("self_leave", "true");
	}

	attachAudioContext = (audioTrack: AudioTrack, audioContext: AudioContext, participant: Participant) => {
		// Create an AnalyserNode
		const analyser = audioContext.createAnalyser();
		analyser.fftSize = 256;

		// Create a ScriptProcessorNode
		const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);
		scriptProcessor.connect(audioContext.destination);

		// Connect the track's MediaStreamSource to the AnalyserNode and ScriptProcessorNode
		const source = audioContext.createMediaStreamSource(new MediaStream([audioTrack.mediaStreamTrack]));
		source.connect(analyser);
		source.connect(scriptProcessor);

		// Listen for audio processing events and calculate the volume
		scriptProcessor.onaudioprocess = (audioProcessingEvent: AudioProcessingEvent) => {
			const array = new Uint8Array(analyser.frequencyBinCount);
			analyser.getByteFrequencyData(array);
			let values = 0;

			const length = array.length;
			for (let i = 0; i < length; i++) {
				values += (array[i]);
			}

			const average = values / length;

			// If the volume is above a certain threshold, set the participant as the dominant speaker
			if (average > 50) { // Adjust the threshold value as needed
				this.setState({
					d: participant
				})
				// Add your logic here to set the participant as the dominant speaker
			}
		}
	}

	//setting state in update coz at time of mounting room may be still empty
	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
		//RUNS WHEN ONLY LOCAL PARTICIPANT IS THERE IN THE ROOM
		if (this.state.allActiveParticipants.length >= 1 && (this.state.d === null)) {
			// when dominantOne is null then it gets updated to local
			this.setState({
				d: this.state.local
			}, () => {
				this.removeListenerForPrevTracks(prevState?.d);
				this.removingMainScreenTracks();
				this.attachingDominantSpeaker();
			})
		}
		if (this.props?.room?.dominantSpeaker && prevState?.d !== this.props?.room?.dominantSpeaker) {
			this.removeListenerForPrevTracks(prevState?.d);
			this.removingMainScreenTracks();
			this.attachingDominantSpeaker();
		}

		//BUTTON TOGGLING LOGIC BY PUBLISHING/UNPUBLISHING LOCAL PARTICIPANT TRACKS
		if (prevState.audioStatus !== this.state.audioStatus) {
			if (this.state.audioStatus) {
				let audioTrackPublication: any = Array.from(this.state.local.audioTracks.values());
				this.enableAudio(audioTrackPublication);
			} else {
				let audioTrackPublication: any = Array.from(prevState.local.audioTracks.values());
				this.disableAudio(audioTrackPublication);
			}
		}
		if (prevState.videoStatus !== this.state.videoStatus) {
			if (this.state.videoStatus) {
				let videoTrackPublication: any = Array.from(this.state.local.videoTracks.values());
				this.enableVideo(videoTrackPublication);
			} else {
				let videoTrackPublication: any = Array.from(prevState.local.videoTracks.values());
				this.disableVideo(videoTrackPublication);
			}
		}

		if (prevState?.allActiveParticipants && prevState.allActiveParticipants !== this.state.allActiveParticipants) {
			this.props.handleStateUplifting(this.state.allActiveParticipants);
		}
		if (prevProps?.allRoomParticipants && prevProps.allRoomParticipants !== this.props.allRoomParticipants) {
			let identityAndId = new Map();
			this.props.allRoomParticipants?.forEach((participant: any) => {
				identityAndId.set(participant.user_name, {
					"account_id": participant.account_id,
					"is_anonymously": participant.is_anonymously,
					"anonymously_name": participant.anonymously_name,
					"profile_img": participant.profile_img,
					"is_host": participant?.is_host || false,
					"is_moderator": participant?.is_moderator || false,
					"full_name": participant?.full_name || '',
					"retract_leave_status": participant?.retract_leave_status,
				})
			});

			this.setState({ identityAndId })
		}
		if (prevState.allActiveParticipants.length !== this.state.allActiveParticipants.length) {
			this.handleRemoteParticipant();
		}
	}

	async componentWillUnmount() {
		let participantList = document.querySelector('.participant-list');
		if (participantList) participantList.innerHTML = '';

		this.removingMainScreenTracks();
	}

	async receive(from: string, message: Message) {
		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			let apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
			let errorJson = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
			let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
			if (this.iceBreakerMessageId === apiCallId) {
				console.log("RESPONSE", responseJson);
				console.log("ERROR", errorJson);
			}
		}
	}

	addParticipant(participant: any) {
		this.setState({
			allActiveParticipants: [...this.state.allActiveParticipants, participant]
		});
	}

	removeParticipant(participant: any) {

		this.setState({
			allActiveParticipants: this.state.allActiveParticipants.filter(
				(p: any) => p.identity !== participant.identity
			)
		});
	}

	attachLocalVideoTrack = (videoTrackPublication: any) => {
		videoTrackPublication.forEach((trackPublished: any) => {
			if (trackPublished?.track) {
				if (trackPublished?.track?.isEnabled === false) this.setState({ mainVideoTrackDisabled: true })
				trackPublished.on('trackEnabled', () => {
					this.setState({ mainVideoTrackDisabled: false })
				})
				trackPublished.on('trackDisabled', () => {
					this.setState({ mainVideoTrackDisabled: true });
				})
			}

			let localScreen = document.querySelector('#local-screen');
			const video_screens = localScreen?.getElementsByTagName("video");

			// TO remove the last video screen
			if (video_screens?.length) {
				localScreen?.removeChild(video_screens[0]);
			}
			try {
				localScreen?.appendChild(trackPublished?.track?.attach());
			} catch (err) {
				if (err instanceof Error)
					console.log("Err in append child", err.message);
			}
		})
	}

	disableAudio = (audioTrackPublication: any) => {
		audioTrackPublication.forEach((trackPublished: any) => {
			trackPublished.track.disable();
		})
	}
	enableAudio = (audioTrackPublication: any) => {
		audioTrackPublication.forEach((trackPublished: any) => {
			trackPublished.track.enable();
		})
	}
	disableVideo = (videoTrackPublication: any) => {
		videoTrackPublication.forEach((trackPublished: any) => {
			trackPublished.track.disable();
		})
	}
	enableVideo = (videoTrackPublication: any) => {
		videoTrackPublication.forEach((trackPublished: any) => {
			trackPublished.track.enable();
		})
	}
	handleAudio = () => {
		this.setState({ audioStatus: !this.state.audioStatus });
	};
	handleVideo = () => {
		this.setState({ videoStatus: !this.state.videoStatus });
	};

	attachingDominantSpeaker = () => {
		let videoTrackPublication: any = Array.from(this.state?.d?.videoTracks?.values());
		if (!videoTrackPublication.length) {
			this.setState({ mainVideoTrackDisabled: true });
		} else
			this.attachLocalVideoTrack(videoTrackPublication);
	}
	removingMainScreenTracks = () => {
		this.setState({ mainVideoTrackDisabled: false });
		let localScreenHtmlEle = document.querySelector('#local-screen');

		if (localScreenHtmlEle) {
			localScreenHtmlEle.innerHTML = "";
		}
	}
	removeListenerForPrevTracks = (previousDominant: any) => {
		if (previousDominant) {
			let videoTrackPublication: any = Array.from(previousDominant.videoTracks.values());

			videoTrackPublication.forEach((trackPublished: any) => {
				if (trackPublished) {
					trackPublished.removeAllListeners();
				}
			})
		}
	}

	handleAlertCount = (x: number) => {
		this.setState({ selfAlertCount: x })
	}

	handleLeaveCount = (x: number) => {
		this.setState({ selfLeaveCount: x })
	}

	handleMaxAlert = () => {
		this.setState({ showMaxAlert: true },
			() => setTimeout(() => this.setState({ showMaxAlert: false }), 5000)
		)
	}

	handleMaxLeave = () => {
		this.setState({ showMaxLeave: true },
			() => setTimeout(() => this.setState({ showMaxLeave: false }), 5000)
		)
	}


	handleLeave = (e: any) => {
		if (e) { e.preventDefault(); }

		const owner = this.props.allRoomParticipants.filter(
			(user: any) => user.account_id === this.props.myAccountId);

		if (this.state.leaveRequestNotificationCount >= 2) {
			this.setState({ leave: true, leaveRequestNotificationCount: 0 })
		}
		// checks if timer trigger the leave button
		else if (moment(this.props.endTime) <= moment()) {
			this.setState({ leave: true })
		}
		else if (this.props.isModerated && !this.state.selfLeave && (owner[0].is_host && owner[0].is_moderator)) {
			this.setState({ selfLeave: true })
		}
		else {
			if (this.props.feedbackData.size === 0 && this.props.joined_users.length > 0) {
				this.props.createFeedbackUsersArray();
			}
			this.setState({ selfLeave: false, leave: true });
		}
	}
	sendIceBreakerRequest = async () => {
		let reqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
		reqMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
		reqMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_different_rooms/rooms/join_room`);
		const header = {
			token: await getStorageData("token"),
			"Content-Type": "application/json"
		};
		reqMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));


		const raw = JSON.stringify({
			"room_id": this.props.history.location.pathname.split('/')[2]
		});
		reqMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), raw);

		this.iceBreakerMessageId = reqMsg.messageId;
		runEngine.sendMessage(reqMsg.id, reqMsg);
	}

	endCallForUser = async () => {
		const token = await getStorageData("token");

		if (token) {
			const header = {
				// "Content-Type": configJSON.contentType,
				token: token
			};

			const requestMessage = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
			);

			this.forceRemoveUserId = requestMessage.messageId;

			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				configJSON.endCallEndPoint
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(header)
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.endCallMethod
			);

			const [_, ID] = window.location.href.split("Room/");
			const formData = new FormData();
			formData.append("room_id", ID);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestBodyMessage),
				formData
			);

			runEngine.sendMessage(requestMessage.id, requestMessage);

			// Redirect the user to home page 
			this.props.history.push('/Rooms?type=my');
		}
	}

	leaveRoomForUser = async () => {
		const token = await getStorageData("token");

		if (token) {
			const header = {
				// "Content-Type": configJSON.contentType,
				token: token
			};

			const requestMessage = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
			);

			this.leaveRoomForUserID = requestMessage.messageId;

			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				configJSON.leaveCallAPI
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(header)
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.endCallMethod
			);

			const ID = window.location.href.split('/Room/')[1].split("?")[0]
			const formData = new FormData();
			formData.append("room_id", ID);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestBodyMessage),
				formData
			);

			runEngine.sendMessage(requestMessage.id, requestMessage);
			// Close the Modal
			this.toggleLeaveModal();
			// Redirect the user to home page 
			this.props.history.push('/Rooms?type=my');
		}
	}

	leaveCallForBothUser = async () => {
		const token = await getStorageData("token");

		if (token) {
			const header = {
				// "Content-Type": configJSON.contentType,
				token: token
			};

			const requestMessage = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
			);

			this.leaveCallForBothUserID = requestMessage.messageId;

			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				configJSON.leaveCallForBothUserAPI
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(header)
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.endCallMethod
			);

			const ID = window.location.href.split('/Room/')[1].split("?")[0]
			const formData = new FormData();
			formData.append("room_id", ID);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestBodyMessage),
				formData
			);

			runEngine.sendMessage(requestMessage.id, requestMessage);

			// Close the Modal
			this.toggleLeaveModal();
			// Redirect the user to home page 
			this.props.history.push('/Rooms?type=my');
		}
	}

	earnedYCoins = async () => {
		const token = await getStorageData("token");

		if (token) {
			const header = {
				// "Content-Type": configJSON.contentType,
				token: token
			};

			const requestMessage = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
			);

			this.earnedYCoinsID = requestMessage.messageId;

			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				configJSON.earnedYCoinsEndPoint
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(header)
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.earnedYCoinsMethod
			);

			const ID = window.location.href.split('/Room/')[1].split("?")[0]
			const formData = new FormData();
			formData.append("room_id", ID);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestBodyMessage),
				formData
			)

			runEngine.sendMessage(requestMessage.id, requestMessage);
		}
	}

	toggleLeaveModal = () => this.setState(prev => ({ leaveModalOpen: !prev.leaveModalOpen }));
}
// Customizable Area End